<template lang="pug">
  #receipt-page
    .grid-layout-container.alignment-block.pb-6(style="width:100%;")
      vs-row(vs-w='12' vs-type='flex', vs-justify='flex-end')

        vs-col.px-1(vs-type='flex', vs-justify='flex-start', vs-align='center', vs-lg='2', vs-sm='2', vs-xs='12'  )
          vs-button(vs-type="flex" vs-align="center" vs-justify="center" style=" margin-top: -50px !important;  margin-bottom: 50px !important; width:100%; padding-left:0; padding-right:0; background: rgb(74, 74, 74); " @click="printInvoice()"  type="filled"  )
            i.material-icons(style="font-size:18px; margin-right:5px;vertical-align:middle;") print
            span พิมพ์


    #invoice-page(style="margin-top:-60px !important;", vs-w='12' , vs-justify='center' )
      .invoice-container(  ref="invContainer" style=" border-color: #777; "  :style="dynamicStyle"  )
        vs-row( class="pt-6", vs-w='12', vs-type='flex', vs-align='flex-end', vs-justify='center')
          img(style='width: 100px;', :src='theLogo()')

        vs-row(class="pt-6" vs-w='12' vs-type='flex', vs-justify='flex-end')
          vs-col.px-1(style="font-size: 1.5rem; font-weight: bold;" vs-type='flex', vs-justify='center', vs-align='center', vs-lg='12', vs-sm='12', vs-xs='12')
            div ใบเสร็จรับเงิน

        vs-row(class="px-6 " vs-w='12' vs-type='flex', vs-justify='flex-end')
          vs-col.px-1.little-font(vs-type='flex', vs-justify='center', vs-align='center', vs-lg='12', vs-sm='12', vs-xs='12')
            span เลขที่: {{orderInfo.orderHeader.orderNumber}}
        vs-row(  class="px-6 " vs-w='12' vs-type='flex', vs-justify='flex-end')
          vs-col.px-1.little-font(v-if="recpCategory!=='so'", vs-type='flex', vs-justify='center', vs-align='center', vs-lg='12', vs-sm='12', vs-xs='12')
            span กำหนดรับ-คืน: {{format_ddmmyyy(orderInfo.orderHeader.pickupDate)}} ~ {{format_ddmmyyy(orderInfo.orderHeader.returnDate)}}

        //*** ============== ข้อมูลร้าน ==============
        div(v-if="orderInfo.branch != null && orderInfo.branch != undefined")
          vs-row(class="pl-6 pt-2" vs-w='12' vs-type='flex', vs-justify='flex-end')
            vs-col.px-1.wraptext(vs-type='flex', vs-justify='flex-start', vs-align='center', vs-lg='12', vs-sm='12', vs-xs='12')
              span ร้าน {{orderInfo.shopInfo.name}} {{orderInfo.branch.branchName}}

          vs-row(class="pl-6 " vs-w='12' vs-type='flex', vs-justify='flex-end')
            vs-col.px-1.wraptext(vs-type='flex', vs-justify='flex-start', vs-align='center', vs-lg='12', vs-sm='12', vs-xs='12')
              span ที่อยู่ {{orderInfo.branch.address}}

          vs-row( v-if="orderInfo.branch.phone" class="pl-6 " vs-w='12' vs-type='flex', vs-justify='flex-end')
            vs-col.px-1.wraptext(vs-type='flex', vs-justify='flex-start', vs-align='center', vs-lg='12', vs-sm='12', vs-xs='12')
              span โทร. {{orderInfo.branch.phone}}

          vs-row(v-if="orderInfo.branch.line" class="pl-6 " vs-w='12' vs-type='flex', vs-justify='flex-end')
            vs-col.px-1.wraptext(vs-type='flex', vs-justify='flex-start', vs-align='center', vs-lg='12', vs-sm='12', vs-xs='12')
              span Line {{orderInfo.branch.line}}

          vs-row( v-if="orderInfo.branch.workingTime" class="pl-6" vs-w='12' vs-type='flex', vs-justify='flex-end')
            vs-col.px-1(vs-type='flex', vs-justify='flex-start', vs-align='center', vs-lg='12', vs-sm='12', vs-xs='12')
              span {{orderInfo.branch.workingTime}}

          vs-row(class="pl-6" vs-w='12' vs-type='flex', vs-justify='flex-end')
            vs-col.px-1(vs-type='flex', vs-justify='flex-start', vs-align='center', vs-lg='12', vs-sm='12', vs-xs='12')
              span -------------------------------------------------------

        div(v-if="recpCategory==='so'")
          vs-row(class="pl-6 pt-2" vs-w='12' vs-type='flex', vs-justify='flex-end')
            vs-col.px-1.wraptext(vs-type='flex', vs-justify='flex-start', vs-align='center', vs-lg='12', vs-sm='12', vs-xs='12')
              span ร้าน {{orderInfo.shopInfo.name}}
          vs-row(class="pl-6" vs-w='12' vs-type='flex', vs-justify='flex-end')
            vs-col.px-1(vs-type='flex', vs-justify='flex-start', vs-align='center', vs-lg='12', vs-sm='12', vs-xs='12')
              span -------------------------------------------------------



        //*** ============== END ==============

        //*** ============== ข้อมูลผู้เช่า ==============

        vs-row(class="pl-6 pt-2" vs-w='12' vs-type='flex', vs-justify='flex-end')
          vs-col.px-1(vs-type='flex', vs-justify='flex-start', vs-align='center', vs-lg='12', vs-sm='12', vs-xs='12')
            span ชื่อผู้เช่า: {{orderInfo.orderHeader.customerName}}

        vs-row(class="pl-6" vs-w='12' vs-type='flex', vs-justify='flex-end')
          vs-col.px-1(vs-type='flex', vs-justify='flex-start', vs-align='center', vs-lg='12', vs-sm='12', vs-xs='12')
            span เบอร์ติดต่อ: {{orderInfo.orderHeader.customerPhone}}

        vs-row(class="pl-6" vs-w='12' vs-type='flex', vs-justify='flex-end')
          vs-col.px-1(vs-type='flex', vs-justify='flex-start', vs-align='center', vs-lg='12', vs-sm='12', vs-xs='12')
            span ที่อยู่: {{orderInfo.orderHeader.customerAddress}}


        vs-row(class="pl-6 pt-2" vs-w='12' vs-type='flex', vs-justify='flex-end')
          vs-col.px-1(vs-type='flex', vs-justify='flex-start', vs-align='center', vs-lg='12', vs-sm='12', vs-xs='12')
            span -------------------------------------------------------

        //*** ============== END ==============

        //*** ============== รายการสินค้า ==============

        vs-row(class="pl-6 pt-2" vs-w='12' vs-type='flex', vs-justify='flex-end')
          vs-col.px-1.title-font.wraptext(vs-type='flex', vs-justify='flex-start', vs-align='center', vs-lg='12', vs-sm='12', vs-xs='12')
            //- span <b>รายการสินค้าเช่า</b>

        vs-row( v-for="(item,i) in orderInfo.orderDetail" :key="i" class="pl-6 pt-2" vs-w='12' vs-type='flex', vs-justify='flex-end')

          //- จำนวนสินค้า
          vs-col.px-1.wraptext(v-if="recpCategory==='so'", vs-type='flex', vs-justify='flex-start', vs-align='center', vs-lg='1', vs-sm='1', vs-xs='1')
            span {{item.qty}}
          vs-col.px-1.wraptext(v-else, vs-type='flex', vs-justify='flex-start', vs-align='center', vs-lg='1', vs-sm='1', vs-xs='1')
            span {{item.reservedQuantity}}

          //- ชื่อสินค้า
          vs-col.px-1.wraptext(vs-type='flex', vs-justify='flex-start', vs-align='center', vs-lg='7', vs-sm='7', vs-xs='7')
            span <b>{{item.name}}</b>

          //- ราคาสินค้า
          vs-col.pr-10.title-font.wraptext(v-if="recpCategory==='so'", vs-type='flex', vs-justify='flex-end', vs-align='center', vs-lg='4', vs-sm='4', vs-xs='4')
            span {{formatPrice2Point(item.price)}}
          vs-col.pr-10.title-font.wraptext(v-else, vs-type='flex', vs-justify='flex-end', vs-align='center', vs-lg='4', vs-sm='4', vs-xs='4')
            span {{formatPrice2Point(item.rentalPrice)}}

          //- code
          vs-col.little-font.wraptext(vs-type='flex', vs-justify='flex-start', vs-align='center', vs-lg='1', vs-sm='1', vs-xs='1')
            i.vs-icon.notranslate.icon-scale.vs-button--icon.material-icons.null(v-if="getRemoved(i) === 'y'" style='order: 0;text-align:left;  font-size:20px; color:red; cursor:pointer;') cancel
          vs-col.little-font.wraptext(v-if="recpCategory!=='so'", vs-type='flex', vs-justify='flex-start', vs-align='center', vs-lg='11', vs-sm='11', vs-xs='11')
            span ({{item.code}})

          //- ค่าประกัน
          vs-col.little-font.wraptext(vs-type='flex', vs-justify='flex-start', vs-align='center', vs-lg='1', vs-sm='1', vs-xs='1')
          vs-col.wraptext( v-if="recpCategory!=='so'", vs-type='flex', vs-justify='flex-start', vs-align='center', vs-lg='7', vs-sm='7', vs-xs='7')
            span  ค่าประกัน
          vs-col.pr-10.wraptext(v-if="recpCategory!=='so'", vs-type='flex', vs-justify='flex-end', vs-align='center', vs-lg='4', vs-sm='4', vs-xs='4')
            span  {{formatPrice2Point(item.bail)}}


        vs-row(class="pl-6 pt-2" vs-w='12' vs-type='flex', vs-justify='flex-end')
          vs-col.px-1.wraptext(vs-type='flex', vs-justify='flex-start', vs-align='center', vs-lg='12', vs-sm='12', vs-xs='12')
            span -------------------------------------------------------


        //*** ============== END ==============

        //*** ============== Settlement ==============

        .grid-layout-container.alignment-block( v-if="recpCategory === 'so'" , style="width:100%;")

          //*** ราคารวมทั้งสิ้น
          vs-row.normal-font(class="pr-2 pt-6" vs-w='12' vs-type='flex', vs-justify='flex-end' )
            vs-col.px-1(vs-type='flex', vs-justify='flex-end', vs-align='center', vs-lg='7', vs-sm='7', vs-xs='7')
              span <b>ราคารวมทั้งสิ้น: </b>
            vs-col.pr-6(vs-type='flex', vs-justify='flex-end', vs-align='center', vs-lg='5', vs-sm='5', vs-xs='5')
              span {{formatPrice2Point(orderInfo.orderHeader.grandTotal)}}</b>

          //- //*** ส่วนลดค่าเช่า
          //- vs-row.normal-font( v-if="orderInfo.orderHeader.rentalDiscount > 0" class="pr-2 " vs-w='12' vs-type='flex', vs-justify='flex-end')
          //-   vs-col.px-1(vs-type='flex', vs-justify='flex-end', vs-align='center', vs-lg='7', vs-sm='7', vs-xs='7')
          //-     span(style="font-size:0.8rem;") <b>ส่วนลดค่าเช่า: </b>
          //-   vs-col.pr-6(vs-type='flex', vs-justify='flex-end', vs-align='center', vs-lg='5', vs-sm='5', vs-xs='5')
          //-     span(style="font-size:0.8rem;") {{formatPrice2Point(orderInfo.orderHeader.rentalDiscount)}}

          //- //*** ส่วนลดโปรโมชั่น
          //- vs-row.normal-font( v-if="orderInfo.orderHeader.promotionDiscount > 0" class="pr-2 " vs-w='12' vs-type='flex', vs-justify='flex-end')
          //-   vs-col.px-1(vs-type='flex', vs-justify='flex-end', vs-align='center', vs-lg='7', vs-sm='7', vs-xs='7')
          //-     span(style="font-size:0.8rem;") <b>ส่วนลดโปรโมชั่น: </b>
          //-   vs-col.pr-6(vs-type='flex', vs-justify='flex-end', vs-align='center', vs-lg='5', vs-sm='5', vs-xs='5')
          //-     span(style="font-size:0.8rem;") {{formatPrice2Point(orderInfo.orderHeader.promotionDiscount)}}


          //- //*** ยอดสุทธิ
          //- vs-row.normal-font(v-if="orderInfo.orderHeader.vatSystem===true", class="pr-2 " vs-w='12' vs-type='flex', vs-justify='flex-end' )
          //-   vs-col.px-1(vs-type='flex', vs-justify='flex-end', vs-align='center', vs-lg='7', vs-sm='7', vs-xs='7')
          //-     span <b>ยอดเช่าสุทธิ: </b>
          //-   vs-col.pr-6(vs-type='flex', vs-justify='flex-end', vs-align='center', vs-lg='5', vs-sm='5', vs-xs='5')
          //-     //- span {{ formatPrice2Point(rentalPriceTotal_final(orderInfo.orderHeader.rentalPriceTotal, orderInfo.orderHeader.rentalDiscount)) }}
          //-     span {{ formatPrice2Point(orderInfo.orderHeader.netPrice) }}

          //- //*** VAT
          //- vs-row.normal-font( v-if="orderInfo.orderHeader.vatSystem===true" class="pr-2 " vs-w='12' vs-type='flex', vs-justify='flex-end')
          //-   vs-col.px-1(vs-type='flex', vs-justify='flex-end', vs-align='center', vs-lg='7', vs-sm='7', vs-xs='7')
          //-     span(style="font-size:0.8rem; font-weight:200;") <b>VAT ({{orderInfo.orderHeader.taxRate}}%): </b>
          //-   vs-col.pr-6(vs-type='flex', vs-justify='flex-end', vs-align='center', vs-lg='5', vs-sm='5', vs-xs='5')
          //-     //- span(style="font-size:0.8rem;") {{ formatPrice2Point(vat_final(orderInfo.orderHeader.rentalPriceTotal, orderInfo.orderHeader.rentalDiscount)) }}
          //-     span {{ formatPrice2Point(orderInfo.orderHeader.vat) }}


          //- //-*** Before VAT
          //- vs-row.normal-font( v-if="orderInfo.orderHeader.vatSystem===true" class="pr-2 " vs-w='12' vs-type='flex', vs-justify='flex-end')
          //-   vs-col.px-1(vs-type='flex', vs-justify='flex-end', vs-align='center', vs-lg='7', vs-sm='7', vs-xs='7')
          //-     span(style="font-size:0.8rem; font-weight:200;") <b>Before VAT: </b>
          //-   vs-col.pr-6(vs-type='flex', vs-justify='flex-end', vs-align='center', vs-lg='5', vs-sm='5', vs-xs='5')
          //-     //- span(style="font-size:0.8rem;") {{ formatPrice2Point(beforeVat_final(orderInfo.orderHeader.rentalPriceTotal, orderInfo.orderHeader.rentalDiscount)) }}
          //-     span {{ formatPrice2Point(orderInfo.orderHeader.beforeVat) }}


          //- //-**** เงินประกัน
          //- vs-row.normal-font(class="pr-2 " vs-w='12' vs-type='flex', vs-justify='flex-end')
          //-   vs-col.px-1(vs-type='flex', vs-justify='flex-end', vs-align='center', vs-lg='7', vs-sm='7', vs-xs='7')
          //-     span <b>รวมค่าประกัน: </b>
          //-   vs-col.pr-6(vs-type='flex', vs-justify='flex-end', vs-align='center', vs-lg='5', vs-sm='5', vs-xs='5')
          //-     span {{formatPrice2Point(orderInfo.orderHeader.bailTotal)}}</b>

          //- //*** ส่วนลดเงินประกัน
          //- vs-row.normal-font( v-if="orderInfo.orderHeader.bailDiscount > 0" class="pr-2 " vs-w='12' vs-type='flex', vs-justify='flex-end')
          //-   vs-col.px-1(vs-type='flex', vs-justify='flex-end', vs-align='center', vs-lg='7', vs-sm='7', vs-xs='7')
          //-     span(style="font-size:0.8rem;") <b>ส่วนลดเงินประกัน: </b>
          //-   vs-col.pr-6(vs-type='flex', vs-justify='flex-end', vs-align='center', vs-lg='5', vs-sm='5', vs-xs='5')
          //-     span(style="font-size:0.8rem;") {{formatPrice2Point(orderInfo.orderHeader.bailDiscount)}}


          //*** ใช้ Point Voucher

          //- div( v-if="recpCategory==='so'", , class="grid grid-flow-col grid-rows-2 grid-cols-3 mt-4")
          //-   div(class="pl-4" style="font-size:0.8rem;"  ) ส่วนลด Voucher
          //-   div(class="pl-4" style="font-size:0.8rem;" ) {{formatPrice2Point(orderInfo.orderHeader.voucherDiscount)}}
          //-   div(class="pl-4" style="font-size:0.8rem;" ) ใช้พอยต์
          //-   div(class="pl-4" style="font-size:0.8rem;" ) {{formatPrice2Point(orderInfo.orderHeader.pointDiscount)}}
          //-   div(class="pl-4" style="font-size:0.8rem;" ) พอยต์ที่ได้
          //-   div(class="pl-4" style="font-size:0.8rem;" ) {{formatPrice2Point(orderInfo.orderHeader.getPoint)}}


        .grid-layout-container.alignment-block( v-else , style="width:100%;")

          //*** รวมค่าเช่า
          vs-row.normal-font(class="pr-2 pt-6" vs-w='12' vs-type='flex', vs-justify='flex-end' )
            vs-col.px-1(vs-type='flex', vs-justify='flex-end', vs-align='center', vs-lg='7', vs-sm='7', vs-xs='7')
              span <b>รวมค่าเช่า: </b>
            vs-col.pr-6(vs-type='flex', vs-justify='flex-end', vs-align='center', vs-lg='5', vs-sm='5', vs-xs='5')
              span {{formatPrice2Point(orderInfo.orderHeader.rentalPriceTotal)}}</b>

          //*** ส่วนลดค่าเช่า
          vs-row.normal-font( v-if="orderInfo.orderHeader.rentalDiscount > 0" class="pr-2 " vs-w='12' vs-type='flex', vs-justify='flex-end')
            vs-col.px-1(vs-type='flex', vs-justify='flex-end', vs-align='center', vs-lg='7', vs-sm='7', vs-xs='7')
              span(style="font-size:0.8rem;") <b>ส่วนลดค่าเช่า: </b>
            vs-col.pr-6(vs-type='flex', vs-justify='flex-end', vs-align='center', vs-lg='5', vs-sm='5', vs-xs='5')
              span(style="font-size:0.8rem;") {{formatPrice2Point(orderInfo.orderHeader.rentalDiscount)}}

          //*** ส่วนลดโปรโมชั่น
          vs-row.normal-font( v-if="orderInfo.orderHeader.promotionDiscount > 0" class="pr-2 " vs-w='12' vs-type='flex', vs-justify='flex-end')
            vs-col.px-1(vs-type='flex', vs-justify='flex-end', vs-align='center', vs-lg='7', vs-sm='7', vs-xs='7')
              span(style="font-size:0.8rem;") <b>ส่วนลดโปรโมชั่น: </b>
            vs-col.pr-6(vs-type='flex', vs-justify='flex-end', vs-align='center', vs-lg='5', vs-sm='5', vs-xs='5')
              span(style="font-size:0.8rem;") {{formatPrice2Point(orderInfo.orderHeader.promotionDiscount)}}


          //*** ยอดเช่าสุทธิ
          vs-row.normal-font( class="pr-2 " vs-w='12' vs-type='flex', vs-justify='flex-end' )
            vs-col.px-1(vs-type='flex', vs-justify='flex-end', vs-align='center', vs-lg='7', vs-sm='7', vs-xs='7')
              span <b>ยอดเช่าสุทธิ: </b>
            vs-col.pr-6(vs-type='flex', vs-justify='flex-end', vs-align='center', vs-lg='5', vs-sm='5', vs-xs='5')
              //- span {{ formatPrice2Point(rentalPriceTotal_final(orderInfo.orderHeader.rentalPriceTotal, orderInfo.orderHeader.rentalDiscount)) }}
              span {{ formatPrice2Point(orderInfo.orderHeader.netPrice) }}

          //*** VAT
          vs-row.normal-font( v-if="orderInfo.orderHeader.vatSystem===true" class="pr-2 " vs-w='12' vs-type='flex', vs-justify='flex-end')
            vs-col.px-1(vs-type='flex', vs-justify='flex-end', vs-align='center', vs-lg='7', vs-sm='7', vs-xs='7')
              span(style="font-size:0.8rem; font-weight:200;") <b>VAT ({{orderInfo.orderHeader.taxRate}}%): </b>
            vs-col.pr-6(vs-type='flex', vs-justify='flex-end', vs-align='center', vs-lg='5', vs-sm='5', vs-xs='5')
              //- span(style="font-size:0.8rem;") {{ formatPrice2Point(vat_final(orderInfo.orderHeader.rentalPriceTotal, orderInfo.orderHeader.rentalDiscount)) }}
              span {{ formatPrice2Point(orderInfo.orderHeader.vat) }}


          //-*** Before VAT
          vs-row.normal-font( v-if="orderInfo.orderHeader.vatSystem===true" class="pr-2 " vs-w='12' vs-type='flex', vs-justify='flex-end')
            vs-col.px-1(vs-type='flex', vs-justify='flex-end', vs-align='center', vs-lg='7', vs-sm='7', vs-xs='7')
              span(style="font-size:0.8rem; font-weight:200;") <b>Before VAT: </b>
            vs-col.pr-6(vs-type='flex', vs-justify='flex-end', vs-align='center', vs-lg='5', vs-sm='5', vs-xs='5')
              //- span(style="font-size:0.8rem;") {{ formatPrice2Point(beforeVat_final(orderInfo.orderHeader.rentalPriceTotal, orderInfo.orderHeader.rentalDiscount)) }}
              span {{ formatPrice2Point(orderInfo.orderHeader.beforeVat) }}


          //-**** เงินประกัน
          vs-row.normal-font(class="pr-2 " vs-w='12' vs-type='flex', vs-justify='flex-end')
            vs-col.px-1(vs-type='flex', vs-justify='flex-end', vs-align='center', vs-lg='7', vs-sm='7', vs-xs='7')
              span <b>รวมค่าประกัน: </b>
            vs-col.pr-6(vs-type='flex', vs-justify='flex-end', vs-align='center', vs-lg='5', vs-sm='5', vs-xs='5')
              span {{formatPrice2Point(orderInfo.orderHeader.bailTotal)}}</b>

          //-*** ส่วนลดเงินประกัน
          vs-row.normal-font( v-if="orderInfo.orderHeader.bailDiscount > 0" class="pr-2 " vs-w='12' vs-type='flex', vs-justify='flex-end')
            vs-col.px-1(vs-type='flex', vs-justify='flex-end', vs-align='center', vs-lg='7', vs-sm='7', vs-xs='7')
              span(style="font-size:0.8rem;") <b>ส่วนลดเงินประกัน: </b>
            vs-col.pr-6(vs-type='flex', vs-justify='flex-end', vs-align='center', vs-lg='5', vs-sm='5', vs-xs='5')
              span(style="font-size:0.8rem;") {{formatPrice2Point(orderInfo.orderHeader.bailDiscount)}}


          //-*** ยอดประกันสุทธิ
          vs-row.normal-font( class="pr-2 " vs-w='12' vs-type='flex', vs-justify='flex-end')
            vs-col.px-1(vs-type='flex', vs-justify='flex-end', vs-align='center', vs-lg='7', vs-sm='7', vs-xs='7')
              span <b>ยอดประกันสุทธิ: </b>
            vs-col.pr-6(vs-type='flex', vs-justify='flex-end', vs-align='center', vs-lg='5', vs-sm='5', vs-xs='5')
              span {{formatPrice2Point(orderInfo.orderHeader.bailTotal - orderInfo.orderHeader.bailDiscount)}}

          //-*** ยอดรวมทั้งหมด
          vs-row.normal-font( class="pr-2 " vs-w='12' vs-type='flex', vs-justify='flex-end')
            vs-col.px-1(vs-type='flex', vs-justify='flex-end', vs-align='center', vs-lg='7', vs-sm='7', vs-xs='7')
              span <b>ยอดรวมทั้งหมด: </b>
            vs-col.pr-6(vs-type='flex', vs-justify='flex-end', vs-align='center', vs-lg='5', vs-sm='5', vs-xs='5')
              span {{formatPrice2Point(  orderInfo.orderHeader.netPrice + (orderInfo.orderHeader.bailTotal - orderInfo.orderHeader.bailDiscount)   )}}

          //-*** ใช้ Point Voucher

          //-*** EDIT 10Dec2022
          //- div( v-if="recpCategory==='so'", class="grid grid-flow-col grid-rows-2 grid-cols-3 mt-4")
          div( class="grid grid-flow-col grid-rows-2 grid-cols-3 mt-4")
            div(class="pl-4" style="font-size:0.8rem;"  ) ส่วนลด Voucher
            div(class="pl-4" style="font-size:0.8rem;" ) {{formatPrice2Point(orderInfo.orderHeader.voucherDiscount)}}
            div(class="pl-4" style="font-size:0.8rem;" ) ใช้พอยต์
            div(class="pl-4" style="font-size:0.8rem;" ) {{formatPrice2Point(orderInfo.orderHeader.pointDiscount)}}
            div(class="pl-4" style="font-size:0.8rem;" ) พอยต์ที่ได้
            div(class="pl-4" style="font-size:0.8rem;" ) {{formatPrice2Point(orderInfo.orderHeader.getPoint)}}


        //- //*** รับเงิน
        //- vs-row.normal-font(class="pr-2 pt-2" vs-w='12' vs-type='flex', vs-justify='flex-end')
        //-   vs-col.px-1(vs-type='flex', vs-justify='flex-end', vs-align='center', vs-lg='7', vs-sm='7', vs-xs='7')
        //-     span <b>รับเงิน: </b>
        //-   vs-col.pr-6(vs-type='flex', vs-justify='flex-end', vs-align='center', vs-lg='5', vs-sm='5', vs-xs='5')
        //-     span {{formatPrice2Point(20000)}}</b>

        //- //*** ทอนเงิน
        //- vs-row.normal-font(class="pr-2 pt-2" vs-w='12' vs-type='flex', vs-justify='flex-end')
        //-   vs-col.px-1(vs-type='flex', vs-justify='flex-end', vs-align='center', vs-lg='7', vs-sm='7', vs-xs='7')
        //-     span <b>เงินทอน: </b>
        //-   vs-col.pr-6(vs-type='flex', vs-justify='flex-end', vs-align='center', vs-lg='5', vs-sm='5', vs-xs='5')
        //-     span {{formatPrice2Point(0)}}</b>




        vs-row(class="pl-6 pt-2" vs-w='12' vs-type='flex', vs-justify='flex-end')
          vs-col.px-1(vs-type='flex', vs-justify='flex-start', vs-align='center', vs-lg='12', vs-sm='12', vs-xs='12')
            span -------------------------------------------------------
        //*** ============== END ==============

        //*** ============== QR CODE ==============

        vs-row(class="px-6 pt-6" vs-w='12' vs-type='flex', vs-justify='flex-end')
          vs-col.px-1(vs-type='flex', vs-justify='center', vs-align='center', vs-lg='12', vs-sm='12', vs-xs='12')
            .grid-layout-container.alignment-block(style="width:100%;")
              vs-row(vs-w='12' class="px-4" vs-type="flex" vs-justify="center" style="font-size:1.5rem;")
                .limit-barcode
                  qr-canvas(:options="theOptions(orderInfo.orderHeader.orderNumber)")
              vs-row(vs-w='12' class="px-4" vs-type="flex" vs-justify="center" style="font-size:1.5rem;")
                span.little-font {{orderInfo.orderHeader.orderNumber}}


        vs-row(class="pl-6 pt-2" vs-w='12' vs-type='flex', vs-justify='flex-end')
          vs-col.px-1(vs-type='flex', vs-justify='flex-start', vs-align='center', vs-lg='12', vs-sm='12', vs-xs='12')
            span -------------------------------------------------------
        //*** ============== END ==============

        //*** ============== เงื่อนไขกาfรประกัน ==============

        vs-row(v-if="recpCategory==='or'", class="px-6 pt-2" vs-w='12' vs-type='flex', vs-justify='flex-end')
          vs-col.px-1(vs-type='flex', vs-justify='flex-start', vs-align='center', vs-lg='12', vs-sm='12', vs-xs='12')
            div.little-font.wraptext(v-html="orderInfo.shopInfo.termAndCondition"  )

        vs-row(v-if="recpCategory==='or'",class="px-6 pt-2" vs-w='12' vs-type='flex', vs-justify='flex-end')
          vs-col.px-1(vs-type='flex', vs-justify='flex-start', vs-align='center', vs-lg='12', vs-sm='12', vs-xs='12')
            span -------------------------------------------------------

        //*** ============== END ==============

        //*** ============== LAST SECTION ==============

        vs-row(class="px-6 pt-2" vs-w='12' vs-type='flex', vs-justify='flex-end')
          vs-col.px-1(vs-type='flex', vs-justify='center', vs-align='center', vs-lg='12', vs-sm='12', vs-xs='12')
            span <b>ขอบคุณค่ะ</b>

        vs-row(class="px-6 pt-2" vs-w='12' vs-type='flex', vs-justify='flex-end')
          vs-col.px-1(vs-type='flex', vs-justify='flex-start', vs-align='center', vs-lg='12', vs-sm='12', vs-xs='12')
            span -------------------------------------------------------

        //*** ============== END ==============
        vs-row(class="px-6 pt-2" vs-w='12' vs-type='flex', vs-justify='flex-end')
          vs-col.px-1(vs-type='flex', vs-justify='flex-end', vs-align='center', vs-lg='12', vs-sm='12', vs-xs='12')
            span.very-little-font Powered by Alex Rental App

</template>
<script>
// import shapeFormat from "@/mixins/shapeFormat.js";
import shapeFormat from "../../mixins/shapeFormat.js";
import VueBarcode from "vue-barcode";

const { QRCanvas } = require('qrcanvas-vue');

export default {
  mixins: [shapeFormat],
  data() {
    return {
      props: {
        orderid: String,
        cate: '',
      },
      dynamicStyle: {},
      height: 125,
      shopinfo: {
        name: "",
        phone: "",
        line: "",
        termAndCondition: ""
      },
      orderInfo: {},
      switch1: true,
      qrPrint: 2,
      pageNum: 1,
      pageNumQr:1,
      pageSize: 12,
      productList: [],
      transformList: [],
      transformListQr:[],
      categories: [],
      textures: [],
      colors: [],
      sizes: [],
      NumberOfLine:0, recpCategory: ""
    };
  },



  watch:{
    switch1(){

      if(this.switch1 === true){
        this.qrPrint = 2;
      }else{
        this.qrPrint = 1;
      }

    }
  },

  async created() {

      this.orderHeaderId = this.$route.params.orderid;
      this.recpCategory = this.$route.params.cat;


      var  apiUrl = "/api/orderheader/receipt-info/";

      if(this.recpCategory === "so"){
        apiUrl = "/api/orderheader/receipt-sale-info/";
      }


      var resp = null;
      try {
        resp = await this.$http.post(
          this.$store.state.apiURL + apiUrl + this.orderHeaderId,
          {},
          this.$store.state.auth.apiHeader
        );
        this.orderInfo = resp.data;

        console.log("this.orderInfo >>> ",this.orderInfo);

        console.log("this.orderInfo.shopInfo >>> ", this.orderInfo.shopInfo);

      } catch (err) {
        this.$swal({
          icon: 'warning',
          title: 'ไม่สามารถรับข้อมูลได้',
        });
      }

      // this.dynamicStyle = {"height" : "300mm"};


  },

  computed: {
    pageSizeQr:{
      get(){
        return Math.ceil(this.pageSize / 2);
      }
    },
    cssVars() {
      return {
        '--height_t': this.height + 'mm'
      }
    }
  },
  async mounted() {


    this.$emit("setAppClasses", "invoice-page");

    // console.log('matchHeight ',this.matchHeight());



    // ****** QR Code

  },

  methods: {

    getRemoved(ind) {
      return this.orderInfo.orderDetail[ind].flag1;
    },

     matchHeight () {
        var h =  Number(this.$refs.invContainer.clientHeight) * 0.2645833333;
        return h + "mm"
      },

    theLogo(){
      if (
        this.orderInfo.shopInfo.logo === undefined ||
        this.orderInfo.shopInfo.logo === "" ||
        this.orderInfo.shopInfo.logo === null
      ) {
        return this.$store.state.baseImageStore + "logo.png";
      } else {
        // console.log('this.$store.state.defaultStore  + this.orderInfo.shopInfo.logo >>> ',this.$store.state.defaultStore  + this.orderInfo.shopInfo.logo);

        return this.$store.state.baseImageStore  + this.orderInfo.shopInfo.logo;
      }
    },
    theOptions(code){
      const options = {
                cellSize: 5,
                correctLevel: 'H',
                data: code,
            }

      return options;
    },
    getName15Char(text){
      if(text === null || text === undefined){
        return "ไม่ระบุ";
      }
      return text.substring(0,15);
    },
    getName12Char(text){
      if(text === null || text === undefined){
        return "ไม่ระบุ";
      }
      return text.substring(0,11);
    },
    getName7Char(text){
      if(text === null || text === undefined){
        return "ไม่ระบุ";
      }
      return text.substring(0,6);
    },
    getColor(code){
      // console.log('Colors >>>> ',this.colors);

      var co = this.colors.find(x => x.code === code);
      if(co === null || co === undefined){
        return "ไม่ระบุสี";
      }
      else{
        return co.description;
      }
    },
    getSize(code){
      var co = this.sizes.find(x => x.code === code);
      if(co === null || co === undefined){
        return "ไม่ระบุขนาด";
      }
      else{
        return co.code;
      }
    },
    transform(list) {
      var transform_list = [];

      var inlist = [];

      for (let i = 0; i < list.length; i++) {
        if (i % this.pageSize === 0) {
          inlist = [];
        }

        inlist.push(list[i]);

        if (i % this.pageSize === this.pageSize - 1 || i === list.length - 1) {
          transform_list.push(inlist);
        }
      }

      return transform_list;
    },

    transformForQr(list) {
      var transform_list = [];

      var inlist = [];

      for (let i = 0; i < list.length; i++) {
        if (i % this.pageSizeQr === 0) {
          inlist = [];
        }

        inlist.push(list[i]);

        if (i % (this.pageSizeQr) === (this.pageSizeQr) - 1 || i === list.length - 1) {
          transform_list.push(inlist);
        }
      }

      return transform_list;
    },


    printInvoice() {
      window.print();
    }
  },
  components: { VueBarcode, QrCanvas: QRCanvas, }
  // mounted() {}
};
</script>

<style lang="scss" >



.title-font{
  font-size: 1.1rem;
}
.normal-font{
  font-size: 1.0rem;
}
.small-font{
  font-size: 0.9rem;
}
.little-font{
  font-size: 0.8rem;
}

.very-small-font{
  font-size: 0.7rem;
}

.very-little-font{
  font-size: 0.6rem;
}

.wraptext{
  word-wrap: break-word;
}

// @page {
//   width: 80mm !important;
//   margin: 0;
// }

.table-font {
  font-family: "SukhumvitSet-Medium";
  font-size: 1rem;
}


// .invoice-container {
//       background-color: white;
//       margin : 0 auto  !important ;
//       font-family: "SukhumvitSet-Medium";
//       font-size: 0.9rem;
//       width: 80mm !important;
//       height: 330mm !important;
// }
@media print {

  // @page { size: 80mm 340mm ; }

  html , body {
    margin: 0 !important;
    * {
      visibility: hidden;
      margin: 0 !important;

    }
  }

  body {
    margin: 0 !important;
    background-color: white;
    visibility: hidden !important;


  }


  .invoice-page {
    * {
      visibility: hidden;
    }
  }

    #content-area {
      margin: 0 !important;
    }

    .invoice-container,
    .invoice-container * {
      visibility: visible ;

    }
    .invoice-container {
      background-color: white;
      margin : 0   !important ;

    }
    .invoice-container {
      color:black;
      position: absolute;
      left: 0;
      top: 0;
      box-shadow: none;
      font-family: "SukhumvitSet-Medium";
      font-size: 0.9rem;
      margin : 0   !important ;

    }


}

</style>
